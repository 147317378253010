import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'


export const query = graphql`
  query PromotionTemplateQuery($id: String!) {
    promotion: sanityPromotion(id: {eq: $id}) {
      id
      title
      images {
        _key
        _type
        asset {
          _id
        }
      }
      _rawDescription
    }
  }
`

const PromotionTemplate = props => {
  const {data, errors} = props
  const promotion = data && data.promotion
  const description = promotion._rawDescription && promotion._rawDescription[0].children[0].text
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {promotion && <SEO title={promotion.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <Container>
        
        <h1>{promotion.title}</h1>
        <p>{description}</p>
        {promotion.images &&
            promotion.images.map(im => (
              <img src={imageUrlFor(buildImageObj(im)).width(960)} />
          ))}
      </Container>
    </Layout>
  )
}

export default PromotionTemplate
